<template>
  <ion-page>
    <div class="background-main-view main-view">
      <ion-header class="ion-no-border">
        <main-view-header />
      </ion-header>
      <ion-content
        class="ion-padding-bottom"
        :scroll-events="true"
      >
        <ion-row
          class="text-left ion-no-padding"
        >
          <ion-button
            fill="clear"
            color="transparent"
            size="small"
            style="max-width: 80px;"
            @click="$router.push('/main-view')"
          >
            <img
              src="../assets/images/arrow-go-back-line.svg"
            >
          </ion-button>
          <span class="text-64 text--white">Current <span class="text--primary">Events</span></span>
        </ion-row>
        <ion-grid>
          <ion-row>
            <ion-col
              v-for="{id, buildingName, buildingAddress, buildingPostCode, buildingCity} in landlordBuildings"
              :key="id"
              size-xl="4"
              size-lg="4"
              size-md="6"
              size-sm="12"
              size-xs="12"
              tappable
              @click="$router.push(`/landlord-properties/${id}`)"
            >
              <ion-item
                lines="none"
                detail="false"
                button
                class="rounded-corners item-building text--white"
                :class="eventInBuilding(id).fire.length > 0 ? 'red-border': '' || eventInBuilding(id).leak.length > 0 ? 'red-border' : ''"
              >
                <ion-grid>
                  <ion-row>
                    <ion-col class="border-bottom top-half">
                      <!-- <i class="mdi mdi-office-building text--primary" /> -->
                      <ion-icon
                        :icon="buildingIco"
                        style="font-size: 30px; margin-top: 3px;"
                        class="text--primary"
                      />
                      <div class="building-name">
                        <span class="text-16 text--white text--uppercase">{{ buildingName }}</span><br>
                        <span class="text-14 text--gray">{{ buildingAddress }}, </span>
                        <span class="text-14 text--gray">{{ buildingPostCode }}</span>
                        <span
                          style="padding-left: 5px;"
                          class="text-14 text--gray"
                        >{{ buildingCity }}</span>
                      </div>
                      <i
                        class="mdi mdi-chevron-right text--white"
                        style="font-size: 25px; position: absolute; right: 0; top: 5px;"
                      />
                    </ion-col>
                  </ion-row>
                  <ion-row class="bottom-half ion-no-padding">
                    <ion-col
                      class="ion-no-padding"
                      size-xl="12"
                      size-lg="12"
                      size-md="12"
                      size-sm="12"
                      size-xs="12"
                    >
                      <div
                        class="event-wrapper mt-8 text--white"
                        style="border-bottom: 1px solid rgba(255, 255, 255, 0.06);"
                      >
                        <div style="display: inline-block">
                          <i
                            class="mdi mdi-fire event-icon"
                            :class="eventInBuilding(id).fire.length === 0 ? 'text--white' : 'text--primary'"
                          />
                        </div>
                        <div style="display: inline-block; padding-left: 10px;">
                          <div v-if="eventInBuilding(id).fire.length === 0">
                            No current fire warning
                          </div>
                          <div
                            v-else
                            class="text--primary"
                          >
                            {{ eventInBuilding(id).fire.length }} fire {{ eventInBuilding(id).fire.length > 1 ? 'alerts' : 'alert' }}!
                          </div>
                          <div
                            class="past-week-month mt-5"
                            :class="pastEventsInBuilding(id).firePast7Days.length > 0 ? 'text--primary' : 'text--white'"
                          >
                            <i
                              class="mdi check"
                              :class="pastEventsInBuilding(id).firePast7Days.length > 0 ? 'mdi-alert text--primary' : 'text--green mdi-check'"
                            />{{ pastEventsInBuilding(id).firePast7Days.length }} in past week
                          </div>
                          <div
                            class="past-week-month"
                            :class="pastEventsInBuilding(id).firePast31Days.length > 0 ? 'text--primary' : 'text--white'"
                          >
                            <i
                              class="mdi check"
                              :class="pastEventsInBuilding(id).firePast31Days.length > 0 ? 'mdi-alert text--primary' : 'text--green mdi-check'"
                            />{{ pastEventsInBuilding(id).firePast31Days.length }} in past month
                          </div>
                        </div>
                      </div>
                    </ion-col>
                    <ion-col
                      class="ion-no-padding"
                      size-xl="12"
                      size-lg="12"
                      size-md="12"
                      size-sm="12"
                      size-xs="12"
                    >
                      <div
                        class="event-wrapper mt-8 text--white"
                        style="border-bottom: 1px solid rgba(255, 255, 255, 0.06);"
                      >
                        <div style="display: inline-block">
                          <i
                            class="mdi mdi-water-outline text--white event-icon"
                            :class="eventInBuilding(id).leak.length === 0 ? 'text--white' : 'text--primary'"
                          />
                        </div>
                        <div style="display: inline-block; padding-left: 10px;">
                          <div v-if="eventInBuilding(id).leak.length === 0">
                            No current waterleak warning
                          </div>
                          <div
                            v-else
                            class="text--primary"
                          >
                            {{ eventInBuilding(id).leak.length }} waterleak {{ eventInBuilding(id).leak.length > 1 ? 'alerts' : 'alert' }}!
                          </div>
                          <div
                            class="past-week-month mt-5"
                            :class="pastEventsInBuilding(id).leakPast7Days.length > 0 ? 'text--primary' : 'text--white'"
                          >
                            <i
                              class="mdi check"
                              :class="pastEventsInBuilding(id).leakPast7Days.length > 0 ? 'mdi-alert text--primary' : 'text--green mdi-check'"
                            />{{ pastEventsInBuilding(id).leakPast7Days.length }} in past week
                          </div>
                          <div
                            class="past-week-month"
                            :class="pastEventsInBuilding(id).leakPast31Days.length > 0 ? 'text--primary' : 'text--white'"
                          >
                            <i
                              class="mdi check"
                              :class="pastEventsInBuilding(id).leakPast31Days.length > 0 ? 'mdi-alert text--primary' : 'text--green mdi-check'"
                            />{{ pastEventsInBuilding(id).leakPast31Days.length }} in past month
                          </div>
                        </div>
                      </div>
                    </ion-col>
                    <ion-col
                      class="ion-no-padding"
                      size-xl="12"
                      size-lg="12"
                      size-md="12"
                      size-sm="12"
                      size-xs="12"
                    >
                      <div
                        class="event-wrapper mt-8 text--white"
                      >
                        <div style="display: inline-block">
                          <i class="mdi mdi-thermometer-low text--white event-icon" />
                        </div>
                        <div style="display: inline-block; padding-left: 10px;">
                          <div>
                            No current climate warning
                          </div>
                          <div class="past-week-month">
                            <i class="mdi mdi-check check text--green" />0 in past week
                          </div>
                          <div class="past-week-month">
                            <i class="mdi mdi-check check text--green" />0 in past month
                          </div>
                        </div>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-item>
            </ion-col>
          </ion-row>
        </ion-grid>
        <div style="height: 100px;" />
      </ion-content>
    </div>
  </ion-page>
</template>

<script>
import mainViewHeader from '../components/Headers/MainViewHeaderComponent.vue'

import buildingIco from '../assets/images/building-ico.svg'

export default {
  components: {
    mainViewHeader
  },
  data () {
    return {
      loading: false,
      buildingIco
    }
  },
  computed: {
    landlordBuildings () {
      return this.$store.state.landlordBuildings ? this.$store.state.landlordBuildings : []
    },
    landlordEvents () {
      return this.$store.state.landlordEvents
    }
  },
  watch: {
  },
  created () {
  },
  methods: {
    eventInBuilding (id) {
      const result = {
        leak: [],
        fire: []
      }
      this.$store.state.landlordProperties.forEach(item => {
        if (item.fire === true && item.buildingId === id) {
          const alert = {
            class: 'fire',
            id: item.id,
            propertyName: item.propertyName,
            fire: item.fire
          }
          result.fire.push(alert)
        }
        if (item.waterLeak === true && item.buildingId === id) {
          const alert1 = {
            buildingId: item.buildingId,
            class: 'waterLeak',
            id: item.id,
            propertyName: item.propertyName,
            waterLeak: item.waterLeak
          }
          result.leak.push(alert1)
        }
      })
      return result
    },
    pastEventsInBuilding (id) {
      const landlordEvents = this.landlordEvents

      const result = {
        leakPast7Days: landlordEvents.last7daysLeakEvent.filter(item => item.buildingId === id),
        leakPast31Days: landlordEvents.last31daysLeakEvent.filter(item => item.buildingId === id),
        firePast7Days: landlordEvents.last7DaysFireEvent.filter(item => item.buildingId === id),
        firePast31Days: landlordEvents.last31DaysFireEvent.filter(item => item.buildingId === id)
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/_colors';
@import "~@/theme/_backgrounds";
.text-64 {
    font-size: 36px;
}
.col-bg {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.item-building {
  margin-top: 8px;
  @include bg-box-item;
  --min-height: 100px;
}

.top-half {
    padding: 0 0 5px 0;
     display: inline-flex;
     border-bottom: 1px solid rgba(255, 255, 255, 0.06);
     ion-list {
       padding-bottom: 50px;
     }
     .mdi-office-building {
       font-size: 35px;
     }
     .building-name {
       padding-top: 3px;
       padding-left: 20px;
     }
     .ico {
       font-size: 20px;
     }
     .floors-flats {
       padding: 10px;
     }
}

.bottom-half {
    .event-wrapper {
        padding-bottom: 5px;
        .event-icon {
            position: relative;
            top: -10px;
            font-size: 35px;
        }
        .check {
            padding-right: 10px;
            font-size: 15px;
        }
        .past-week-month{
            font-size: 13px;
        }
    }
}
.red-border {
    border: 1px solid rgba(255, 0, 0, 0.8);
    --background: rgba(74, 63, 63, 0.8);
}
</style>